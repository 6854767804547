.footer {
  background-color: $primary;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 14px;
  width: 100%;
  padding: 0 20px
}
