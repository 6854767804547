.login-page {
	.login-heading {
		padding-block: 64px;
	}
	.login-logo {
		width: 150px !important;
		display: grid;
		place-content: center;
	}
	.login-logo img[alt='main_logo'] {
		width: 100px;
		// height: 65px;
	}
	.login-logo img[alt='campaign_logo'] {
		width: 100px;
		// aspect-ratio: 1 / 1;
	}
	hr {
		border-top: 1px solid gray !important;
	}

	.login-header {
		h5 {
			font-weight: 600 !important;
			font-size: 18px !important;
			line-height: 19.2px !important;
			color: $danger;
			margin: 0 !important;
		}
		h4 {
			font-weight: 400 !important;
			font-size: 16px !important;
			line-height: 16.8px !important;
			// color: #838383;
			color: $danger;
		}
	}

	.title h5 {
		margin-bottom: 5px;
		font-size: 22px;
	}
	.title h3 {
		margin-bottom: 5px;
		font-size: 32px;
		font-weight: 600;
	}
	.login-brand {
		margin: 20px 0;
		margin-bottom: 20px;
		text-align: center;

		img {
			margin-bottom: 10px;
		}
		h3 {
			color: #6677ef;
			font-size: 2rem;
			font-weight: 700;
		}
		p {
			color: #838383;
			font-size: 18px;
		}
	}

	.btn {
		border: 1px solid #d0d0d0;
		border-radius: 8px;
		padding-block: 12px;
		width: 100%;
		font-size: 16px;
		&-neutral {
			background-color: #404040;
			color: white;
		}

		&-white {
			background-color: white;
			color: #404040;
		}

		&-login {
			background-color: #6677ef;
			color: white;
			padding-block: 0.5rem;
			margin-bottom: 0.5rem;
			border: none;
			font-size: 14px;
			font-weight: 600;
			&-sso {
				background-color: #e8eafd;
				color: #6677ef;
			}
		}
		&:hover {
			opacity: 0.9;
			box-shadow: 2px 2px 8px #ccc;
		}
	}

	// .card.card-primary {
	// 	border-top: 2px solid #6777ef;
	// }
	.card .card-header h3 {
		font-size: 16px;
		line-height: 28px;
		color: #6777ef;
		padding-right: 10px;
		margin-bottom: 0;
	}
	.card {
		box-shadow: none;
	}
	.simple-footer {
		text-align: center;
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 14px;
		* {
			color: #424242;
		}
		a {
			text-decoration: underline !important;
		}
	}
	.control-label,
	.form-group .control-label,
	.form-group > label {
		font-weight: 600;
		color: #34395e;
		font-size: 14px;
		letter-spacing: 0.5px;
		width: 100%;
		margin-bottom: 0.25rem;
	}
	.powered-by {
		position: relative;
		margin-bottom: 18px;
		p {
			width: max-content;
			padding-inline: 0.5rem;
			margin-inline: auto;
			background-color: #f3f7fa;
			position: relative;
			z-index: 1;
			color: #424242 !important;
			font-size: 14px;
		}
		&::before {
			content: '';
			position: absolute;
			bottom: 50%;
			border-bottom: 1px solid #d0d0d0;
			width: 100%;
		}
	}
	.powered {
		height: 60px;
		margin-block-end: 21px;
	}
}

.title {
	color: red;
}

.text-small {
	font-size: 12px;
	line-height: 20px;
}
